import React from "react"
import styled from "styled-components"
import Wrapper from "../layout/Wrapper"
import InnerFooter from "./InnerFooter"
import FooterSocialMediaLinks from "./FooterSocialMediaLinks"
import FooterImpressum from "./FooterImpressum"
import {breakpoints} from "../../styles/variables"

const FooterElem = styled.footer`
    padding: 22px 0 50px;
    background: black;
    color: white;
    position: relative;

> div {
     /* display: grid;
    grid-template-columns: 1fr;  */

    /* @media screen AND (max-width: 1023px) {
    >div:first-of-type {
      order: 3  
    }
    >div:last-of-type {
      order: 2;
      grid-row: span 2;
    }
    >div:nth-of-type(2) {
      order: 1; 
    }
  } */
}
@media screen AND (min-width: ${breakpoints.m}px) {
    padding: 30px 0 32px;

    /* > div{
      display: flex;
      justify-content: space-between;

      >div:first-of-type {
        width: 13px;
        
      }
      >div:nth-of-type(2) {
        width: auto;
      }
      >div:last-of-type {
        width: 32px;
      }
    } */
}

@media screen AND (min-width: ${breakpoints.l}px) {
    padding: 37px 0 41px;

    /* > div{
      display: flex;
      justify-content: space-between;

      >div:first-of-type {
        width: 13px;
        
      }
      >div:nth-of-type(2) {
        width: auto;
      }
      >div:last-of-type {
        width: 32px;
      }
    } */
}
`

const Footer = () => 
<FooterElem>
  <FooterImpressum />
  <Wrapper>
    <InnerFooter />
  </Wrapper>
  <FooterSocialMediaLinks />
</FooterElem>

export default Footer