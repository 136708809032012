import React from "react"
import styled from "styled-components"
import {breakpoints, spacers} from "../../styles/variables"

const SocialMediaLinksDiv = styled.div`
height: 168px;
width: 32px;
display: flex;
flex-direction: column;
justify-content: space-between;
position: absolute;
right: ${spacers.s}px;
top: 22px; 


a, img {
    display: block;
}

> img {
    width: 27px;
    height: 68px;
    margin-left: 5px;
}

@media screen AND (min-width: ${breakpoints.m}px) {
   top: 30px;
    right: ${spacers.m}px;

}

@media screen AND (min-width: ${breakpoints.l}px) {
    height: 210px;

}
`

const FooterSocialMediaLinks = () => 
<SocialMediaLinksDiv>
    <a href="https://www.instagram.com/strichpunkt_sg/" target="_blank" rel="noreferrer"><img src="/assets/gestaltungselemente/instagram-negativ.svg" alt="" /></a>
    <img src="/assets/gestaltungselemente/Strichpunkt-Signet-Negativ.svg" alt="" />
</SocialMediaLinksDiv>

export default FooterSocialMediaLinks