import React from "react"
import styled from "styled-components"
import { breakpoints } from "../styles/variables"
import Wrapper from "./layout/Wrapper"

const HeaderImgDiv = styled.section`
    background: url("${props => props.image}");
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 202px;
    height: 40vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        > * {
            max-width: 800px;
            
        }
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        min-height: 326px;
        padding-bottom: 50px;

    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        min-height: 408px;
    }

    @media screen AND (min-width: ${breakpoints.xl}px) {
        min-height: 612px;
    }
`

const HeaderImage = ({image, children}) => <HeaderImgDiv image={image}><Wrapper>{children}</Wrapper></HeaderImgDiv>

export default HeaderImage