import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../styles/variables"

const HeadlineWithSubtitle = styled.article`
    margin-bottom: 26px;
    h1 {
        max-width: 90%;
        margin-bottom: 4px;
    }

    h4 {
        max-width: 90%;
    }

    &.noMaxWidth {
        h1, h4 {
            max-width: 100%;
            margin-right: auto;
            margin-left: 0;
            text-align: left;
        }
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        margin-bottom: 27px;
        

        h1 {
            max-width: 85%;
            margin-bottom: 7px;
            margin-right:0;
            margin-left: auto;
            hyphens: auto;

        }

        h4{
            max-width: 85%;
            margin-right:0;
            margin-left: auto;

        }
    }

     @media screen AND (min-width: ${breakpoints.m}px) {
        margin-bottom: 32px;

        h1 {
            margin-bottom: 7px;
        }
    }
`

const HeadlineWithSub = ({children, noMaxWidth}) => <HeadlineWithSubtitle className={noMaxWidth ? "noMaxWidth" : ""}>{children}</HeadlineWithSubtitle>

export default HeadlineWithSub