import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"

import {breakpoints, spacers} from "../../styles/variables"

const FooterImpressumDiv = styled.div`
        position: absolute;
        left: ${spacers.s}px;
        bottom: 26px;
	      z-index: 100;

@media screen AND (min-width: ${breakpoints.m}px) {
        //padding: 50px 0 0;
        left: ${spacers.m}px;
        
    > p {
        transform: rotate(-90deg);
        transform-origin: left;
        margin-top: 140px;
        width: 114px;
        position: relative;
        text-align: left;
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        > p {
            width: 146px;
            margin-top: 180px; //messes up the image height
        }
    }   
}`

const FooterImpressum = () => 
    <FooterImpressumDiv>
        <p className="impressum">
        ©{new Date().getFullYear()} / <Link to="/kontakt">Impressum</Link>
        </p>
    </FooterImpressumDiv>

export default FooterImpressum
