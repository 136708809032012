import React, {useState} from "react"
import styled from "styled-components"
import Wrapper from "./layout/Wrapper"
import TwoThirdsGrid from "./layout/TwoThirdsGrid"
import { breakpoints } from "../styles/variables"
import AkkordeonItem from "./AkkordeonItem"

const AkkordeonDiv = styled.div`
    background: ${props => props.background};
    color: white;
    padding: 33px 0 40px;

    @media screen AND (min-width: ${breakpoints.m}px) {
        padding: 44px 0 22px;
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        padding: 54px 0 40px;
    }

    @media screen AND (max-width: 1023px) {
        h1, h4 {
            br {
                display: none;
            }
        }
    }
`

const Akkordeon = ({data}) => {

    const [openItem, setOpenItem] = useState(0);

    const textColor = data.background === "#fff" ? 'black' : 'white'

return(
    <>
        {data === undefined ? "" :  
            <AkkordeonDiv background={data.background}>
                <Wrapper>
                    <TwoThirdsGrid textRight>
                        <div style={{ color: textColor }}>
                            <h1 dangerouslySetInnerHTML={{__html: data.title}} />
                            <h4 dangerouslySetInnerHTML={{__html: data.subTitle}} />
                        </div>
                        <div style={{ color: textColor }}>
                            {
                                data.items.map((item, i) => <AkkordeonItem openItem={openItem} setOpenItem={setOpenItem} itemData={item} key={i} id={i} arrow={data.arrow} />)
                            }
                        </div>
                    </TwoThirdsGrid>
                </Wrapper>
            </AkkordeonDiv>
        }
    </>)
}

export default Akkordeon;