import React from "react"
import styled from "styled-components"
import {breakpoints} from "../styles/variables"

const RoundedImg = styled.img`
    display: block;
    width: 100%;
    height: 95%;
    border-radius: 49%;

    &.contentImage {
        margin-bottom: 26px;
        width: 216px;
        height: 205px;

        @media screen AND (min-width: ${breakpoints.m}px) {
            margin-right: 0;
            margin-left: auto;
           width: 234px;
           height: 222px; 
        }

        @media screen AND (min-width: ${breakpoints.l}px) {
            margin-bottom: 32px;
           width: 293px;
           height: 278px; 
        }
    }
`

const RoundedImage = ({image, link, contentImage}) => <>{
    link !== undefined ? <div><a href={link}><RoundedImg src={image} className={contentImage ? "contentImage" : ""} /></a></div> : <div><RoundedImg className={contentImage ? "contentImage" : ""} src={image} /></div>
}</>

export default RoundedImage;
