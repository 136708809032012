import React from "react"
import styled from "styled-components"

import {breakpoints} from "../../styles/variables"
const NavToggleDiv = styled.div`
z-index: 10;
cursor: pointer;
height: 18px;
margin: 23px 0 30px;

> div {
    width: 28px;
    height: 2px;
    background: black;
    margin-bottom: 6px;
    transition: all 300ms;
    &:last-of-type {
        margin-bottom: 0;
    }
}

&.isOpen {
    > div {
        &:nth-of-type(2) {
            background: white;
        }
        &:first-of-type {
            transform: rotate(45deg);
            margin: 6px 0 0;
        }
        &:last-of-type {
            transform: rotate(-45deg);
            margin: -10px 0 0;
        }
    }
}

@media screen AND (min-width: ${breakpoints.m}px) {
    width: 36px;
    height: 22px;
    margin: 27.5px 0 28.5px;


    
    > div {
        width: 36px;
        height: 3px;
        margin-bottom: 7px;

    }

    &.isOpen {
        > div {
            &:nth-of-type(2) {
                background: white;
            }
            &:first-of-type {
                transform: rotate(45deg);
                margin: 10px 0 0;
            }
            &:last-of-type {
                transform: rotate(-45deg);
                margin: -13px 0 0;
            }
        }
    }
}
`

const NavToggle = ({isOpen, setIsOpen}) => <NavToggleDiv onClick={() => setIsOpen(!isOpen)} className={isOpen ? "isOpen" : ""}>
    <div></div>
    <div></div>
    <div></div>
</NavToggleDiv>

export default NavToggle