import React from "react"
import styled from "styled-components"
import { breakpoints } from "../styles/variables"

const ItemDiv = styled.div`
> div:first-of-type {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid white;
    padding: 20px 0 9px;

    img {
        width: 23px;
        height: 22px;
        transition: transform 300ms;

    }
}
    .akkordeon {
        padding-top: 11px;
        transition: max-height 1s ease;
        max-height: 0px;
        overflow: hidden;
    }

    &.isOpen {
        .akkordeon {
            height: auto;
            max-height: 700px;
            transition: max-height 2s ease 1s;
        }
        img {
            transform: rotate(180deg)
        }
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        &:first-of-type {
            > div:first-of-type {
                padding-top: 0;
            }
        }
    }
     @media screen AND (min-width: ${breakpoints.l}px) {

        > div:first-of-type {
            padding: 20px 0 10.5px;
            img {
                width: 29px;
                height: 27px;

            }
        }

        .akkordeon {
            padding-top: 14.5px;
        }
    }
    
`



const AkkordeonItem = ({id, openItem, setOpenItem, itemData, arrow}) => {
    return (
        <ItemDiv className={openItem === id ? "isOpen" : ""}>
            <div onClick={() => id === openItem ? setOpenItem("x") : setOpenItem(id)}><h3>{itemData.title}</h3><img src={arrow} alt="" /></div>
            <div><p className="akkordeon" dangerouslySetInnerHTML={{__html: itemData.content}} /></div>
        </ItemDiv>
    )
}

export default AkkordeonItem