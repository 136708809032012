import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../styles/variables"
import RoundedImage from "../RoundedImage"

const InnerFooterDiv = styled.div`
    width: 100%;

>div:first-of-type, >div:last-of-type > div:last-of-type {
    display: none;
}
h2 {
    margin-bottom: 11px;
}

address {
    a {
        color: white;
    }
}

.links {
  margin-top: 15px;
  font-size: 1rem;
  
  a {
		color: white;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
}  

@media screen AND (min-width: ${breakpoints.m}px) {
    display: grid;
    //grid-template-columns: 168px 168px 168px;
    grid-template-columns: 1fr 2fr;
    column-gap: 50px;

    >div:first-of-type, >div:last-of-type >div:last-of-type {
        display: block;
    }
    
    img {
      width: 168px;
      height: 160px;
    }

    >div:first-of-type {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        > div {
            margin-right:20px
        }
    }

    >div:last-of-type {
        display: grid;
        grid-template-columns: 168px 168px;
        column-gap:  72px;
    }
    
}

@media screen AND (min-width: ${breakpoints.l}px) {
    img {
      width: 210px;
      height: 200px;
    }
    >div:first-of-type {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        > div {
            margin-right:20px
        }
    }

    >div:last-of-type {
        display: grid;
        grid-template-columns: 240px 210px;
        column-gap:  72px;
    }

}
`

const InnerFooter = () => 
    <InnerFooterDiv>
            <div>
                <RoundedImage image="/assets/images/portrait-sw.jpeg" />
            </div>
            <div>
                <div>
                    <h2>strichpunkt</h2>
                    <address>
                        <strong>Mal- & Gestaltungstherapie </strong><br />
                        Evelyn Krapf-Schibli <br />
                        Rorschacherstrasse 109 A <br />
                        CH-9000 St. Gallen <br />
                        Telefon <a href="tel:+41 79 844 04 07">+41 79 844 04 07</a> <br />
                        <a href="mailto:info@strich.sg">info@strich.sg</a>
                    </address>
                    <p className='links'>
                      <a href="/datenschutz">Datenschutzerklärung</a>
                    </p>
                </div>
                <RoundedImage image="/assets/images/maps.png" link="https://goo.gl/maps/o4icoS93PUXew8up9"/>
            </div>
    </InnerFooterDiv>

export default InnerFooter;