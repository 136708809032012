export const breakpoints = {
    m: 1024,
    l:  1280,
    xl: 1920
}

export const spacers = {
    s: 20,
    m: 50,
}

export const heights = {
    navLanding: {
        s: 59,
        m: 148,
        l: 186,
    },
    navDefault: {
        s: 59,
        m: 78,
    }
}