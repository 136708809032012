import React from "react"
import styled from "styled-components"
import { breakpoints, spacers } from "../../styles/variables"

const WrapperDiv = styled.div`
    margin: 0 auto;
    position: relative;
    max-width: ${breakpoints.l}px;
    padding: 0 ${spacers.s}px;

    @media screen AND (min-width: ${breakpoints.m}px) {
        padding: 0 ${spacers.m}px;
    }

    &.xl {
        max-width: 1920px;
    }
`

const Wrapper = ({children, xl}) => <WrapperDiv className={xl ? "xl" : ""}>{children}</WrapperDiv>

export default Wrapper