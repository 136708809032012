import React from "react"
import styled from "styled-components"
import { spacers, breakpoints } from "../../styles/variables"

const TwoThirdsDiv = styled.section`
    width: 100%;
    margin-bottom: 50px;
    @media screen AND (min-width: ${breakpoints.m}px) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-column-gap: ${spacers.m}px;
        margin-bottom: 60px;

        &.textRight > div:first-of-type {
            text-align: right;
        }
    }
`

const TwoThirdsGrid = ({children, textRight}) => <TwoThirdsDiv className={textRight ? "textRight" : ""}>{children}</TwoThirdsDiv>

export default TwoThirdsGrid